<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core';
import * as emailValidator from 'email-validator';
import { useAuthStore } from '@/store/auth';

definePageMeta({
  layout: 'plain',
});

const route = useRoute();
const { isSmallScreen } = useDisplay();

const rules = {
  email: [
    (v: string) => !!v || 'Voer je email adres in',
    (v: string) => emailValidator.validate(v) || 'Dit email adres klopt niet',
  ],
  length: (len: number) => (v: string) =>
    (v || '').length >= len ||
    `Wachtwoord moet uit minimaal 6 karakters bestaan`,
  password: [
    (v: string) => !!v || 'Voer je wachtwoord in',
    // (v: string) => v.length > 5 || "Wachtwoord moet uit minimaal 6 karakters bestaan",
    (v: string) =>
      v.match(/[0-9]/) != null ||
      'Wachtwoord moet minimaal één cijfer bevatten',
    (v: string) =>
      v.match(/[a-z]/) != null ||
      'Wachtwoord moet minimaal één kleine letter bevatten',
    (v: string) =>
      v.match(/[A-Z]/) != null ||
      'Wachtwoord moet minimaal één hoofdletter bevatten',
  ],
};
// const email = ref("");
const email = useLocalStorage('m1-id', '', {
  serializer: {
    read: (v: string) => (v ? atob(JSON.parse(v)) : null),
    write: (v: string) => JSON.stringify(btoa(v)),
  },
  initOnMounted: true,
});
const password = ref('');
const loginForm = ref(null);
const loading = ref(false);

async function applyCredendials(_event: Event) {
  const { $sentry } = useNuxtApp();
  loading.value = true;

  try {
    await logIn(email.value as string, password.value);

    if (
      route.query.redirect &&
      typeof route.query.redirect === 'string' &&
      route.query.redirect !== 'initialize'
    ) {
      logger().info(
        'pages/index.vue',
        'applyCredendials()',
        'Redirect to',
        route.query.redirect
      );

      await go({
        path: '/initialize',
        query: {
          redirect: route.query.redirect,
        },
      });
    } else {
      await go('/initialize');
    }
  } catch (error) {
    $sentry.captureException(error);
    loading.value = false;
  }

  loading.value = false;
}

function count() {
  if (password.value.length > 6) return false;

  return 6;
}

onMounted(async () => {
  logger().debug('pages/index.vue', 'onMounted()', 'start');
  const { user } = useAuthStore();

  if (user?.uid == null) return;

  logger().info(
    'pages/index.vue',
    'onMounted()',
    'Already logged in, navigating to pages'
  );

  await go('/pages');
});
</script>

<template>
  <div class="base-layout bg-surface d-flex justify-space-around align-center">
    <Loader v-if="loading" />
    <v-card
      v-else
      class="login-form elevation-5"
    >
      <v-toolbar
        cards
        flat
        color="primary"
      >
        <v-card-title class="text-h6 font-weight-regular">
          Aanmelden
        </v-card-title>
      </v-toolbar>
      <v-form
        v-model="loginForm"
        class="pa-4 pt-6"
        @keyup.prevent.enter="loginForm && applyCredendials($event)"
      >
        <!-- <client-only> -->
        <v-text-field
          v-model="email"
          :rules="rules.email"
          variant="filled"
          color="primary"
          label="Email adres"
          type="email"
          class="pb-2"
        />
        <!-- </client-only> -->
        <v-text-field
          v-model="password"
          :rules="[...rules.password, rules.length(6)]"
          variant="filled"
          color="primary"
          :counter="count()"
          autofocus
          label="Wachtwoord"
          type="password"
        />
      </v-form>
      <div class="pb-4 px-4">
        <p class="text-body-2">
          Op zoek naar uitleg? Lees de
          <NuxtLink
            tabindex="10"
            to="/docs"
          >
            documentatie
          </NuxtLink>
        </p>
      </div>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="primary"
          to="/register"
        >
          Maak account
        </v-btn>
        <v-btn
          v-if="!isSmallScreen"
          color="primary"
          to="/unregister"
        >
          Verwijder account
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="!loginForm"
          color="primary"
          @click="loginForm && applyCredendials($event)"
        >
          Log in
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<style lang="scss" local>
.base-layout {
  .login-form {
    width: 500px;
  }

  height: 100vh;
  width: 100vw;
}

// Prevent flicker when vuetify switches to dark theme
@media (prefers-color-scheme: dark) {
  .base-layout {
    background-color: rgb(30 30 30) !important;

    .v-card {
      background-color: rgb(30 30 30);
      color: rgb(255 255 255);

      .v-toolbar {
        background: rgb(82 139 255) !important;
      }

      a {
        color: rgb(158 158 255);
      }

      .v-btn {
        color: rgb(82 139 255);
      }
    }
  }
}

@media (width <= 600px) {
  .base-layout {
    .login-form {
      width: auto;
      min-width: 350px;
    }
  }
}
</style>
